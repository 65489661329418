const getFilterStatus = (data: any, id: any, status = String(1)) => {
  try {
    if (!id) {
      let Arr = JSON.parse(JSON.stringify(data));
      Arr = Arr.filter((item: any) => item.status != status);
      return Arr;
    } else return data;
  } catch {
    return [];
  }
};
export default getFilterStatus;
